import { render, staticRenderFns } from "./option.vue?vue&type=template&id=9a0c2aae"
import script from "./option.vue?vue&type=script&lang=js"
export * from "./option.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.4.27_cache-loader@4.1.0_webpack@4.47.0__css-loader@3.6_5quact3f7p3tdvt2xai76qcrm4/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports